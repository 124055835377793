import React from "react"
import { css } from "@emotion/core"
import Image from "../image"
import { breakpoint } from "./../../constants"
import Line from './line'

const root = css`
  display: flex;
  padding: 0 1rem;
  padding-bottom: 0.4rem;
  height: 5.2rem;
  position: relative;
  @media (max-width: ${breakpoint}px) {
    height: auto;
    padding: 4rem 0.3rem 0.7rem 0.3rem;
    flex-direction: column;
  }
`
const photo = css`
  position: absolute;
  overflow: visible;
  width: 30vw;
  top: 0.4rem;
  left: 1rem;
  z-index: 20;
  @media (max-width: ${breakpoint}px) {
    top: -2rem;
    left: 0;
    width: 100vw;
    padding: 0 0.7rem;
    box-sizing: border-box;
  }
`

const intro0 = css`
  position: absolute;
  top: 1rem;
  right: 1rem;
  @media (max-width: ${breakpoint}px) {
    position: static;
    top: 0;
    left: 0;
    margin-bottom: 0.8rem;
  }
`

const intro1 = css`
  position: absolute;
  top: 3rem;
  left: 1.4rem;
  @media (max-width: ${breakpoint}px) {
    position: static;
    top: 0;
    left: 0;
    margin-bottom: 0.8rem;
  }
`

const intro2 = css`
  position: absolute;
  top: 4.4rem;
  left: 2.8rem;
  @media (max-width: ${breakpoint}px) {
    position: static;
    top: 0;
    left: 0;
    margin-bottom: 0.8rem;
  }
`

const introContainer = css`
  width: 40vw;
  height: 2em;
  h4 {
    margin: 0;
    font-size: 1.2em;
    color: #e2bd58;
  }
  p {
    margin: 0;
    font-size: 0.55em;
  }
  @media (max-width: ${breakpoint}px) {
    width: 100%;
    box-sizing: border-box;
    padding: 0 0.4rem;
    height: auto;
    margin: 0.3rem 0;
    h4 {
      font-size: 3em;
    }
    p {
      font-size: 2em;
    }
  }
`
const border = css`
  width: 0;
  height: 0;
  border-left: 100vw solid transparent;
  border-bottom: 0.5rem solid #f1ead8;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 20;
`
const line5Wrapper = css`
  position: absolute;
  top: 1.6rem;
  left: 1.6rem;
  width: 2.1rem;
  z-index: 15;
`
const line6Wrapper = css`
  position: absolute;
  top: 2.2rem;
  left: 1.7rem;
  width: .05rem;
  z-index: 15;
`
const line7Wrapper = css`
  position: absolute;
  top: 3.6rem;
  left: 1.7rem;
  width: 1.3rem;
  z-index: 15;
`
const Intro = ({ time, intro }) => (
  <div css={introContainer}>
    <h4>{time}</h4>
    <p>{intro}</p>
  </div>
)

const CollegeFrontDoor = ({ introductions }) => (
  <div css={root}>
    <div css={photo}>
      <Image filename="assets/home/College photo.png" />
    </div>
    <div css={intro0}>
      <Intro
        time={introductions.collegeFrontDoor[0].time}
        intro={introductions.collegeFrontDoor[0].intro}
      />
    </div>
    <div css={intro1}>
      <Intro
        time={introductions.collegeFrontDoor[1].time}
        intro={introductions.collegeFrontDoor[1].intro}
      />
    </div>
    <div css={intro2}>
      <Intro
        time={introductions.collegeFrontDoor[2].time}
        intro={introductions.collegeFrontDoor[2].intro}
      />
    </div>
    <div css={border} />
    <div css={line5Wrapper}>
      <Line type={4} />
    </div>
    <div css={line6Wrapper}>
      <Line type={1} />
    </div>
    <div css={line7Wrapper}>
      <Line type={5} />
    </div>
  </div>
)

export default CollegeFrontDoor
