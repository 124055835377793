import React from "react"
import { css } from "@emotion/core"
import Image from "./../image"
import StorylineIcon from "./../stroylineIcon"
import { breakpoint } from "./../../constants"
import { useStaticQuery, graphql, Link } from "gatsby"

const root = css`
  display: flex;
  background-color: #F1EAD8;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 3rem;

  h2 {
    text-transform: uppercase;
    font-size: 1em;
    z-index: 20;
  }

  @media (max-width: ${breakpoint}px) {
    height: auto;
    h2 {
      font-size: 4em;
      font-weight: normal;
    }
  }
`


const linksContainer = css`
  box-sizing: border-box;
  padding: 0 1rem;
  width: 100%;
  display: flex;
  justify-content: space-around;
  z-index: 20;
  @media (max-width: ${breakpoint}px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`

const tour = css`
  margin-top: .2rem;
  width: 1rem;
  z-index: 20;
  cursor: pointer;
  outline: none;
  border: 2px solid #000;
  text-decoration: none;
  color: #000;
  font-size: 0.4em;
  text-transform: uppercase;
  font-weight: bolder;
  text-align: center;
  padding: 0.06rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${breakpoint}px) {
    width: 4rem;
    margin-bottom: 3rem;
    font-size: 1.8em;
    padding: 0.3rem;
  }
`

const arrow = css`
  width: 10%;
  margin-left: 2%;
`

const Bottom =  () => {
  const data = useStaticQuery(graphql`
    query {
      allDataJson ( filter: {slug: {regex: "/storylines/"}}) {
        edges{
          node {
            slug
            title
            subTitle
            iconPath {
              relativePath
            }
            url
          }
        }
      }
    }
  `)
  let castleNav, genderNav, classNav
  data.allDataJson.edges.forEach(n => {
    if (n.node.slug==="storylines_castle") {
      castleNav = n.node
    }
    if (n.node.slug==="storylines_class") {
      classNav = n.node
    }
    if (n.node.slug==="storylines_gender") {
      genderNav = n.node
    }
  })
  return (
    <div css={root}>
      <h2>Storylines</h2>
      <div css={linksContainer}>
        <StorylineIcon nav={castleNav} />
        <StorylineIcon nav={genderNav} />
        <StorylineIcon nav={classNav} />
      </div>
      <Link to="/tour" css={tour} >
        start tour
        <div css={arrow}>
          <Image filename="assets/black-arrow.png" />
        </div>
      </Link>
    </div>
  )
}


export default Bottom