import React from "react"
import { css } from "@emotion/core"
import Image from "../image"
import { breakpoint } from "./../../constants"
import { useViewport } from "./../../contexts/viewContext"
import Line from "./line"

const root = css`
  display: flex;
  position: relative;
  padding: 0 1rem;
  height: 2.4rem;
  p {
    margin-top: 0.6rem;
    width: 60%;
    margin-right: 10%;
    font-size: 0.55em;
    z-index: 20;
  }
  @media (max-width: ${breakpoint}px) {
    height: 14rem;
    padding: 0 0.3rem;
    p {
      box-sizing: border-box;
      font-size: 2em;
      margin: 0;
      width: 100%;
      margin-top: 10rem;
      padding: 0 0.4rem;
    }
  }
`
const background = css`
  width: 100vw;
  position: absolute;
  top: -0.3rem;
  left: 0;
  overflow: visible;
  z-index: 10;
  @media (max-width: ${breakpoint}px) {
    top: -1rem;
  }
`

const line1Wrapper = css`
  position: absolute;
  top: -.16rem;
  left: 3rem;
  width: .05rem;
  z-index: 20;
`

const line2Wrapper = css`
  position: absolute;
  top: 2rem;
  left: 5.2rem;
  width: .05rem;
  z-index: 20;
`

const Robert = ({introductions}) => {
  const { width } = useViewport()
  let imgPath = ""
  width > breakpoint
    ? (imgPath = "assets/home/desktop")
    : (imgPath = "assets/home/mobile")
  return (
    <div css={root}>
      <div css={background}>
        <Image filename={`${imgPath}/Robert-block.png`} />
      </div>
      <p>{introductions.robert}</p>
      <div css={line1Wrapper}>
        <Line type={1} />
      </div>
      <div css={line2Wrapper}>
        <Line type={1} />
      </div>
    </div>
  )
}

export default Robert
