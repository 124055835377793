import React from "react"
import { css } from "@emotion/core"
import { Link } from "gatsby"
import Image from "./image"
import { breakpoint } from "./../constants"
import PropTypes from "prop-types"

const iconContainer = css`
  width: 1.6rem;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #000;
  h6 {
    font-size: 0.6em;
    margin: 0;
  }
  p {
    font-size: 0.4em;
    margin: 0;
    font-weight: 600;
  }
  @media (max-width: ${breakpoint}px) {
    width: 100%;
    padding: 0 0.3rem;
    margin-bottom: 0.6rem;
    box-sizing: border-box;
    h6 {
      font-size: 2.8em;
    }
    p {
      font-size: 2em;
      font-weight: bold;
    }
  }
`

const icon = css`
  width: 1rem;
  height: 1rem;
  margin-bottom: 0.1rem;
  @media (max-width: ${breakpoint}px) {
    width: 4rem;
    height: 4rem;
    margin-bottom: 0.2rem;
  }
`

const StorylineIcon = ({ nav }) => {
  return (
    <div css={iconContainer}>
      <Link css={icon} to={nav.url}>
        <Image filename={nav.iconPath.relativePath} />
      </Link>
      <h6>{nav.title}</h6>
      <p>{nav.subTitle}</p>
    </div>
  )
}

StorylineIcon.propTypes = {
  nav: PropTypes.object.isRequired
}

export default StorylineIcon
