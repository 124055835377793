import React from "react"
import Image from "../image"
import { css } from "@emotion/core"
import { breakpoint } from "./../../constants"
import { useViewport } from "./../../contexts/viewContext"

const root = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const section = css`
  box-sizing: border-box;
  width: 100%;
  margin-top: 0.1rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  position: relative;
  @media (max-width: ${breakpoint}px) {
    padding: 0 0.3rem;
    flex-direction: column;
  }
`

const background = css`
  position: absolute;
  top: 0.2rem;
  left: 0;
  width: 100vw;
  z-index: -10;
  height: 2.4rem;
`

const imgWrapper = css`
  width: 35%;
  margin-right: 5%;
  @media (max-width: ${breakpoint}px) {
    width: 80%;
    margin-bottom: 0.4rem;
  }
`

const desc = css`
  font-size: 0.8em;
  color: #9b251a;
  width: 100%;
  @media (max-width: ${breakpoint}px) {
    font-size: 2.1em;
    box-sizing: border-box;
    padding: 0 0.4rem;
  }
`

const intro = css`
  width: 60%;
  font-size: 0.55em;
  @media (max-width: ${breakpoint}px) {
    width: 100%;
    font-size: 2.1em;
    margin: 0;
    padding: 0 0.4rem;
    box-sizing: border-box;
  }
`

const logo = css`
  width: 60%;
  max-width: 666px;
  margin-top: 0.1rem;
  @media (max-width: ${breakpoint}px) {
    margin-top: 1.2rem;
    width: 90%;
  }
`

const bottom = css`
  height: 2.4rem;
  overflow: hidden;
  @media (max-width: ${breakpoint}px) {
    height: 10rem;
    padding: 1.2rem 0.3rem;
  }
`

const imgCss = css`
  width: 100%;
  @media (max-width: ${breakpoint}px) {
    width: 500%;
    transform: translateX(-70%);
  }
`

const scroll = css`
  margin-top: .1rem;
  width: 0.6rem;
  outline: none;
`

const AboutTheCastle = ({introductions}) => {
  const { width, height } = useViewport()
  const handleScroll = () => {
    let currentY = window.pageYOffset
    let targetY = currentY + height
    scrollAnimation(currentY, targetY)
  }
  const scrollAnimation = (currentY, targetY) => {
    let needScrollTop = targetY - currentY
    let _currentY = currentY
    setTimeout(() => {
      const dist = Math.ceil(needScrollTop / 10)
      _currentY += dist
      window.scrollTo(_currentY, currentY)
      if (needScrollTop > 10 || needScrollTop < -10) {
        scrollAnimation(_currentY, targetY)
      } else {
        window.scrollTo(_currentY, targetY)
      }
    }, 1)
  }
  return (
    <div css={root}>
      <div
        css={css`
          ${section};
          flex-direction: column;
        `}
      >
        <div css={logo}>
          <Image filename="assets/home/logo.png" />
        </div>
        <p css={css`${desc}; font-family: "LoraRegular"`}>{introductions.aboutTheCastle[0]}</p>
      </div>
      <div css={section}>
        <div css={imgWrapper}>
          <Image filename="assets/home/castle.png" />
        </div>
        <p
          css={css`
            ${intro};
            color: #000;
          `}
        >
          {introductions.aboutTheCastle[1]}
        </p>
      </div>

      {width > breakpoint && (
        <div
          css={scroll}
          onClick={handleScroll}
          onKeyDown={handleScroll}
          tabIndex={0}
          role="button"
        >
          <Image filename="assets/home/scroll.png" />
        </div>
      )}

      <div
        css={css`
          ${section};
          ${bottom};
        `}
      >
        <div css={background}>
          <Image filename="assets/home/blue-block.png" imgCss={imgCss} />
        </div>
        <p
          css={css`
            ${intro};
            color: #fff3d2;
            margin-top: 0.4rem;
          `}
        >
          {introductions.aboutTheCastle[2]}
        </p>
      </div>
    </div>
  )
}
export default AboutTheCastle
