import React from "react"
import { css } from "@emotion/core"
import Image from "../image"
import { breakpoint } from "./../../constants"
import { useViewport } from "./../../contexts/viewContext"
import Line from "./line"

const root = css`
  display: flex;
  padding: 0 1rem;
  height: 2.4rem;
  position: relative;
  p {
    margin-top: 0.8rem;
    width: 40%;
    margin-right: 10%;
    font-size: 0.55em;
    z-index: 20;
  }
  @media (max-width: ${breakpoint}px) {
    height: 10rem;
    padding: 0 0.3rem;
    p {
      box-sizing: border-box;
      font-size: 2em;
      margin: 0;
      width: 100%;
      margin-top: 4rem;
      padding: 0 0.4rem;
    }
  }
`
const background = css`
  width: 100vw;
  position: absolute;
  top: -0.2rem;
  left: 0;
  overflow: visible;
  z-index: 10;
  @media (max-width: ${breakpoint}px) {
    top: -1rem;
  }
`

const line = css`
  position: absolute;
  overflow: visible;
  width: 100vw;
  left: 0;
  top: -0.09rem;
  z-index: 12;
  @media (max-width: ${breakpoint}px) {
    top: -0.3rem;
  }
`

const castle = css`
  position: absolute;
  overflow: visible;
  width: 50vw;
  right: 0.4rem;
  top: -0.2rem;
  z-index: 16;
  @media (max-width: ${breakpoint}px) {
    top: -1rem;
    left: 0;
    width: 100vw;
    padding: 0 0.7rem;
    box-sizing: border-box;
  }
`

const line4Wrapper = css`
  position: absolute;
  top: 1.2rem;
  left: 3.2rem;
  width: .5rem;
  z-index: 20;
`

const CastlePostcard = ({introductions}) => {
  const { width } = useViewport()
  let imgPath = ""

  width > breakpoint
    ? (imgPath = "assets/home/desktop")
    : (imgPath = "assets/home/mobile")
  return (
    <div css={root}>
      <p>{introductions.castlePostcard}</p>
      <div css={background}>
        <Image filename={`${imgPath}/paper-block.png`} />
      </div>
      <div css={line}>
        <Image filename={`${imgPath}/Line.png`} />
      </div>
      <div css={castle}>
        <Image filename={`${imgPath}/castle2.png`} />
      </div>
      <div css={line4Wrapper}>
        <Line type={3} />
      </div>
    </div>
  )
}

export default CastlePostcard
