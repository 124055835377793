import React from "react"
import { css } from "@emotion/core"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AboutTheCastle from "./../components/home/AboutTheCastle"
import Robert from "./../components/home/Robert"
import Joan from "./../components/home/Joan"
import CastlePostcard from "./../components/home/CastlePostcard"
import CollegeFrontDoor from "./../components/home/CollegeFrontDoor"
import Bottom from "./../components/home/bottom"
import { useStaticQuery, graphql } from "gatsby"

const home = css`
  margin-top: 0.2rem;
  display: flex;
  font-size: 0.2rem;
  flex-direction: column;
`

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      dataJson( slug: {eq: "home_introduction"}) {
        aboutTheCastle
        robert
        joan
        castlePostcard
        collegeFrontDoor {
          time
          intro
        }
      }
    }
  `)
  const introductions = data.dataJson
  return (
    <Layout title="home">
      <SEO title="Home" />
      <div css={home}>
        <AboutTheCastle introductions={introductions} />
        <Robert introductions={introductions} />
        <Joan introductions={introductions} />
        <CastlePostcard introductions={introductions} />
        <CollegeFrontDoor introductions={introductions} />
        <Bottom />
      </div>
    </Layout>
  )
}

export default IndexPage
