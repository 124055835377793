import React from "react"
import { css } from "@emotion/core"
import { breakpoint } from "./../../constants"
import Image from "../image"

const imgCss = css`
  width: 100%;
  @media (max-width: ${breakpoint}px) {
    display: none;
  }
`

const Line = ({type}) => (
  <Image filename={`assets/home/line-type${type}.png`} imgCss={imgCss}/>
)

export default Line